@font-face {
  font-family: 'extop-main-icons';
  src:  url('fonts/extop-main-icons.eot?9ah6cc');
  src:  url('fonts/extop-main-icons.eot?9ah6cc#iefix') format('embedded-opentype'),
    url('fonts/extop-main-icons.ttf?9ah6cc') format('truetype'),
    url('fonts/extop-main-icons.woff?9ah6cc') format('woff'),
    url('fonts/extop-main-icons.svg?9ah6cc#extop-main-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-ex-"], [class*=" icon-ex-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'extop-main-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ex-menu-currency:before {
  content: "\e953";
}
.icon-ex-menu-logs:before {
  content: "\e959";
}
.icon-ex-menu-network:before {
  content: "\e95a";
}
.icon-ex-menu-statistic:before {
  content: "\e95b";
}
.icon-ex-menu-transaction:before {
  content: "\e95c";
}
.icon-ex-menu-ashier:before {
  content: "\e95d";
}
.icon-ex-help:before {
  content: "\e958";
}
.icon-ex-chevron-down:before {
  content: "\e956";
}
.icon-ex-chevron-up:before {
  content: "\e957";
}
.icon-ex-add:before {
  content: "\e900";
}
.icon-ex-arrow-left:before {
  content: "\e901";
}
.icon-ex-arrow-right:before {
  content: "\e902";
}
.icon-ex-clear:before {
  content: "\e903";
}
.icon-ex-copy:before {
  content: "\e904";
}
.icon-ex-delete:before {
  content: "\e905";
}
.icon-ex-download:before {
  content: "\e906";
}
.icon-ex-edit:before {
  content: "\e907";
}
.icon-ex-filter:before {
  content: "\e908";
}
.icon-ex-refresh:before {
  content: "\e909";
}
.icon-ex-save:before {
  content: "\e90a";
}
.icon-ex-withdrawal:before {
  content: "\e90b";
}
.icon-ex-tab-info:before {
  content: "\e90c";
}
.icon-ex-tab-pair:before {
  content: "\e90d";
}
.icon-ex-tab-personal-data:before {
  content: "\e90e";
}
.icon-ex-tab-profit:before {
  content: "\e90f";
}
.icon-ex-tab-referral-link:before {
  content: "\e910";
}
.icon-ex-tab-referrals:before {
  content: "\e911";
}
.icon-ex-tab-security:before {
  content: "\e912";
}
.icon-ex-tab-settings:before {
  content: "\e913";
}
.icon-ex-tab-statistic:before {
  content: "\e914";
}
.icon-ex-filter-by:before {
  content: "\e915";
}
.icon-ex-filter-calendar:before {
  content: "\e916";
}
.icon-ex-filter-date-of-completion .path1:before {
  content: "\e917";
  color: rgb(12, 175, 42);
}
.icon-ex-filter-date-of-completion .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(144, 150, 177);
}
.icon-ex-filter-date-of-completion .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(144, 150, 177);
}
.icon-ex-filter-date-of-creation .path1:before {
  content: "\e91a";
  color: rgb(144, 150, 177);
}
.icon-ex-filter-date-of-creation .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(144, 150, 177);
}
.icon-ex-filter-date-of-creation .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(77, 110, 221);
}
.icon-ex-filter-get .path1:before {
  content: "\e91d";
  color: rgb(144, 150, 177);
}
.icon-ex-filter-get .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(12, 175, 42);
}
.icon-ex-filter-send .path1:before {
  content: "\e91f";
  color: rgb(144, 150, 177);
}
.icon-ex-filter-send .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(225, 28, 61);
}
.icon-ex-input-close:before {
  content: "\e921";
}
.icon-ex-input-credit-card:before {
  content: "\e922";
}
.icon-ex-input-email:before {
  content: "\e923";
}
.icon-ex-input-eye:before {
  content: "\e93e";
}
.icon-ex-input-eye-slash:before {
  content: "\e93f";
}
.icon-ex-input-id:before {
  content: "\e924";
}
.icon-ex-input-input-amount:before {
  content: "\e925";
}
.icon-ex-input-input-calendar:before {
  content: "\e926";
}
.icon-ex-input-location:before {
  content: "\e927";
}
.icon-ex-input-name:before {
  content: "\e928";
}
.icon-ex-input-phone:before {
  content: "\e929";
}
.icon-ex-input-url:before {
  content: "\e92a";
}
.icon-ex-input-usename:before {
  content: "\e92b";
}
.icon-ex-input-wallet:before {
  content: "\e92c";
}
.icon-ex-menu-balances:before {
  content: "\e92d";
}
.icon-ex-menu-black-list:before {
  content: "\e92e";
}
.icon-ex-menu-cash-operations:before {
  content: "\e92f";
}
.icon-ex-menu-database:before {
  content: "\e940";
}
.icon-ex-menu-exchangers:before {
  content: "\e941";
}
.icon-ex-menu-exchange-rate:before {
  content: "\e930";
}
.icon-ex-menu-exit:before {
  content: "\e931";
}
.icon-ex-menu-hide-left:before {
  content: "\e942";
}
.icon-ex-menu-hide-right:before {
  content: "\e943";
}
.icon-ex-menu-orders:before {
  content: "\e932";
}
.icon-ex-menu-pairs:before {
  content: "\e933";
}
.icon-ex-menu-payment-system:before {
  content: "\e934";
}
.icon-ex-menu-plugins:before {
  content: "\e944";
}
.icon-ex-menu-referral:before {
  content: "\e935";
}
.icon-ex-menu-report:before {
  content: "\e936";
}
.icon-ex-menu-requisites:before {
  content: "\e937";
}
.icon-ex-menu-servers:before {
  content: "\e945";
}
.icon-ex-menu-services:before {
  content: "\e946";
}
.icon-ex-menu-services-settings:before {
  content: "\e947";
}
.icon-ex-menu-settings:before {
  content: "\e938";
}
.icon-ex-menu-systems:before {
  content: "\e948";
}
.icon-ex-menu-transactions:before {
  content: "\e949";
}
.icon-ex-menu-users:before {
  content: "\e939";
}
.icon-ex-menu-verification:before {
  content: "\e93a";
}
.icon-ex-menu-widgets:before {
  content: "\e93b";
}
.icon-ex-menu-withdrawal-request:before {
  content: "\e93c";
}
.icon-ex-multiselect:before {
  content: "\e93d";
}
.icon-ex-ok:before {
  content: "\e94a";
}
.icon-ex-cancel:before {
  content: "\e94b";
}
.icon-ex-completed-orders:before {
  content: "\e94c";
}
.icon-ex-profit-from-referrals:before {
  content: "\e94d";
}
.icon-ex-profit-from-fees:before {
  content: "\e94e";
}
.icon-ex-orders-list:before {
  content: "\e94f";
}
.icon-ex-referral-percent:before {
  content: "\e950";
}
.icon-ex-referral-income:before {
  content: "\e951";
}
.icon-ex-referral-bonus:before {
  content: "\e952";
}
.icon-ex-warning:before {
  content: "\e954";
}
.icon-ex-support:before {
  content: "\e955";
}
